<template>
  <!-- Header mobile -->
  <q-layout view="hHh lpR fFf" style="min-height: 0">
    <q-header class="text-white fixed-top header-mobile q-mb-xl">
      <q-toolbar class="justify-between" style="height: 100%">
        <router-link v-if="settings.logo_url === ''" to="/">
          <img class="" :src="settings.logo ? settings.logo : defaultImageApp"
            style="max-width: 100%; max-height: 45px" alt="" />
        </router-link>
        <a v-else :href="settings.logo_url">
          <img class="" :src="settings.logo ? settings.logo : defaultImageApp"
            style="max-width: 100%; max-height: 45px" alt="" />
        </a>

        <div class="flex items-center">
          <div class="points q-mr-sm">
            <q-toolbar-title class="title-points omni-text-secondary">
              {{ points.points.points }} {{ settings.currency_abbreviation }}
              <q-tooltip anchor="bottom middle" self="top middle">
                {{ $t("General.points") }}
              </q-tooltip>
            </q-toolbar-title>
          </div>
          <CircleInfo />
        </div>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>

  <!-- Header desktop -->
  <q-layout view="hHh lpR fFf" style="min-height: 0">
    <q-header
      class="header-desktop text-white fixed-top q-mt-lg q-mx-xl"
      style="background-color: var(--bg-color-primary)"
    >
      <q-toolbar class="justify-between">
        <div class="q-mt-xs" style="width: 100px;">
          <router-link v-if="settings.logo_url === ''" to="/">
            <img class="" :src="settings.logo ? settings.logo : defaultImageApp"
              style="max-width: 100px; max-height: 35px" alt="" />
          </router-link>
          <a v-else :href="settings.logo_url">
            <img class="" :src="settings.logo ? settings.logo : defaultImageApp"
              style="max-width: 100px; max-height: 35px" alt="" />
          </a>
        </div>
        
        <!-- Navigation -->
        <div class="nav-desktop q-pt-md omni-navbar">
          <q-tabs
            v-model="tabDesktop"
            indicator-color="transparent"
            active-class="active-option"
            class="tabs-desktop q-pt-sm"
            style="z-index: 3; justify-content: space-around"
            no-caps
          >
          <q-route-tab icon="home" to="/" exact
            >
            <p>{{ $t("General.home") }}</p></q-route-tab
          >

          <q-route-tab icon="card_giftcard" to="/products" exact
            >
            <p>{{ $t("General.products") }}</p></q-route-tab
          >
          <q-route-tab icon="person" to="/profile" exact
            >
            <p>{{ $t("General.profile") }}</p></q-route-tab
          >
          <!--
          <q-route-tab icon="account_balance_wallet" to="/controlpanel" exact
            >
            <p>{{ $t("General.wallet") }}</p></q-route-tab
          >
          -->
          <q-route-tab icon="receipt" to="/transacciones" exact
            >
            <p>{{ $t("General.transactions") }}</p></q-route-tab
          >
          </q-tabs>
        </div>


        <div class="row items-center ">
          <div class="points">
            <q-toolbar-title class="title-points">
              <div class="column items-end q-mr-sm">
                <div class="omni-text-primary">
                  {{user.name}} {{user.last_name}}
                </div>
                <div class="omni-text-primary omni-text-secondary">
                  {{ points.points.points }} {{ settings.currency_abbreviation }}
                </div>
              </div>
              <q-tooltip anchor="bottom middle" self="top middle">
                {{ $t("General.points") }}
              </q-tooltip>
            </q-toolbar-title>
          </div>
          <CircleInfo class="q-mr-xs" />
          <!-- <q-item>
            <LanguageSwitcher />
          </q-item> -->
          <q-item>
            <LogOut />
          </q-item>
        </div>
      </q-toolbar>
    </q-header>
    <q-page-container>
      <router-view />
    </q-page-container>
  </q-layout>
</template>

<script>
import customer from "@/mixins/customer";
import { defineAsyncComponent } from "vue";

import points from "@/mixins/points";

const LanguageSwitcher = defineAsyncComponent(() =>
  import("../../shared/components/LanguageSwitcher.vue")
);

const CircleInfo = defineAsyncComponent(() =>
  import("../../module-2-main-pages/components/profile/CircleInfo.vue")
);

const LogOut = defineAsyncComponent(() =>
  import("../../module-2-main-pages/components/profile/LogOut.vue")
);

export default {
  mixins: [points, customer],
  components: {
    CircleInfo,
    LanguageSwitcher,
    LogOut,
  },

  data() {
    return {
      tabDesktop: "",
    };
  },
  
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },
  },
  mounted() {
    this.getPoints();
  },
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-header {
  align-items: center;
  display: flex;
  height: 65px;
}

.points {
  /*border-radius: 25px 25px 25px 0px;
  border: 1px solid v-bind("settings.color_primary");*/
  padding: 0.5rem;

  .title-points {
    font-size: 12px;
    font-weight: 600;
    text-transform: capitalize;
  }
}

@media only screen and (min-width: 767px) {
  .header-desktop {
    border-radius: 10px;
    box-shadow: 0 0 1px rgba(0,0,0,0.06), 
                0 0 2px rgba(0,0,0,0.06), 
                0 0 4px rgba(0,0,0,0.06), 
                0 0 8px rgba(0,0,0,0.06),
                0 0 16px rgba(0,0,0,0.06);
  }

  .header-mobile {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .header-mobile {
    display: block;
  }

  .header-desktop {
    display: none;
  }
}

select {
  border-radius: 10px;
  min-width: 100px;
  padding: 5px;
  border: 1px solid v-bind("settings.color_primary");
  height: 36px;
}

.nav-desktop p {
  font-size: 14px;
  text-transform: capitalize;
}
</style>
